<template>
  <static-fullscreen-card>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <a-btn-status
        v-if="getAccess('documents.objectSell.status', { users: [data.createdby_id] })"
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="canStatus"
      />
      <a-btn-edit v-if="data.status !== 2 && accessEdit" @click="showEditDialog = true" :disabled="canEdit" />
      <a-btn-delete v-if="id && data.status !== 2 && getAccess('documents.objectSell.delete', { users: [data.createdby_id] })" @click="removeDialogShow = true" />
    </template>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view
            :value="data"
            :model="
              model.filter((el) => {
                return el.card == 1;
              })
            "
            :config="{ dense: true }"
            :style1="{ height: blockHeight + 'px' }"
          />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view
            :value="balance"
            :model="[
              { name: 'credit', title: 'Итого к оплате', type: 'number' },
              { name: 'debit', title: 'Оплачено', type: 'number' },
              {
                name: 'saldo',
                value: balance.credit - balance.debit,
                title: 'Долг',
                type: 'number',
              },
            ]"
            :config="{ dense: true }"
            :style1="{ height: blockHeight + 'px' }"
            @click="getBalance()"
          />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2 pt-1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item data-name="files section">
              <v-card-text :style="styleDetailCard" class="pa-0">
                <v-fab-transition v-if="true">
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('doc_all')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" />
              </v-card-text>
            </v-tab-item>
            <v-tab-item data-name="cash section">
              <v-card-text :style="styleDetailCard" class="pa-0">
                <a-table-f-api
                  ref="tableCashIn"
                  :api="'/accounting/doc/order_receipt'"
                  :model="getModelList($store.getters['config/get'].models.cashIn)"
                  :searchable="false"
                  :useQuery="false"
                  :defaults="{
                    sort: { key: 'id', order: 'DESC' },
                    filters: {
                      operation_type: m.operationsCash,
                      parent_name: accDocName,
                      parent_id: id,
                    },
                    paramName: 'documentCashIn',
                  }"
                  @click="onClickCash($event)"
                >
                  <template v-slot:top>
                    <v-fab-transition v-if="data.status == 2 && accessEdit">
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="showCashSelectDialog = true">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-api>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <comment-view :id="id || 0" :targetName="'objectSell'" :needUpdate="needUpdateComments" :height="detailHeight" />
            </v-tab-item>
            <v-tab-item data-name="acc section">
              <v-card-text :style="styleDetailCard" class="pa-0">
                <s-document-acc-record :id="id" :name="accDocName" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <edit-dialog
        v-if="showEditDialog && !true"
        v-model="showEditDialog"
        :id="id"
        :api="url"
        :m="m"
        :readonly="data.status == 2"
        :initType="data.type"
        :hasData="data.data_table && data.data_table.length > 0"
      />
      <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="id" />

      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
      <component
        v-if="cashType && showCashCreateDialog"
        :is="cashType"
        v-model="showCashCreateDialog"
        :id="idEditCash"
        :config="configDoc"
        :document="data"
        :api="'/accounting/doc/order_receipt'"
      />

      <select-dialog v-model="showCashSelectDialog" v-if="showCashSelectDialog" :operations="m.operationsCash || []" @select="onSelectOperation($event)" />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </static-fullscreen-card>
</template>

<script>
//import { statusTask } from "@/config/modules/libs";

import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel, comments } from "@/components/mixings";
import libsCash from "../libsCash";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel, libsCash, comments],
  props: {
    idShow: Number,
  },
  components: {
    cashCashBox: () => import("./../dialogs/cashCashBox/editDialog"),
    selectDialog: () => import("./../dialogs/cashOperationSelectDialog"),
    editDialog: () => import("./../dialogs/objectSellDialog"),
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      idEditCash: 0,
      fieldsRO: [],

      accDocName: "AccountingDocBuildingSellModel",
      needUpdateComments: 0,
      showCashSelectDialog: false,
      showCashCreateDialog: false,
      showEditDialog: false,
      showDialogRowEdit: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      loaded: false,
      title: "",
      data: {},
      model1: this.$store.getters["config/get"].models.documentObjectSell.form,
      configHead: null,
      m: this.$store.getters["config/get"].models.documentObjectSell,
      statuses: this.$store.getters["config/get"].models.documentObjectSell.statuses,
      url: "/accounting/doc/building_sell",
      api: "/accounting/doc/building_sell",
      apiStatus: "/accounting/doc/building_sell",
      tab: 0,
      tabs: [
        { title: "Документы", show: true },
        { title: "Оплата", show: true },
        { title: "Комментарии", show: true },
        {
          title: "Проводки",
          show: this.getAccess("documents.objectSell.accounting"),
        },
      ],
      blockHeight: 256,
      debit: null,
      credit: null,
      statusCur: null,
    };
  },
  created() {
    this.loading = true;

    this.$root.title = "Договор на продажу объекта";
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    accessEdit() {
      return this.getAccess("documents.objectSell.edit", { users: [this.data.createdby_id] });
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    canStatus() {
      return false;
    },
    canEdit() {
      return false;
    },
    model: {
      get() {
        let type = this.data.operation_type || "default";
        let config = JSON.parse(JSON.stringify(this.m?.config?.[type] || this.m?.config.default || {}));
        this.configHead = config;
        let model = this.calcModelConfig(config);
        return model;
        //let model = JSON.parse(JSON.stringify(this.m.form));
        model.forEach((el) => {
          if (el?.sourceList) {
            //если список вариантов находится во внешнем массиве, присоединяю его
            el.options = this.m?.[el.sourceList]
              ? this.m[el.sourceList].filter((list) => {
                  return !list?.hidden;
                })
              : [];
          }
        });

        return model;
      },
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
    showCashCreateDialog() {
      if (!this.showCashCreateDialog) {
        this.$refs.tableCashOut[0].updateData();
      }
    },
  },
  methods: {
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },
    async getBalance() {
      console.log("getBalance");
      let debit = 0;
      let credit = 0;
      if (this.data.status !== 2) {
        credit += this.data.value;
      }
      //const acc = this.config.debit;
      let resp = await this.$axios.post("accounting/records/report-sql", {
        q: `
select sum(value_cr) 'value_cr', sum(value_db) 'value_db' from (
select id, credit_account 'account', value 'value_cr', null 'value_db'
from accounting_record
where credit_account like "62.1" and credit_subconto_value_2=${this.id}
union all
select id, debit_account 'account',	null 'value_cr', value 'value_db'
from accounting_record
where debit_account like "62.1" and debit_subconto_value_2=${this.id}
)  tt`,
      });

      if (resp.data.status == "ok") {
        resp.data.data.forEach((el) => {
          debit += el.value_db;
          credit += el.value_cr;
        });
      }
      this.credit = credit;
      this.debit = debit;
    },

    afterRemove(data) {
      this.removeDialogShow = false;
      this.$router.push({ name: "objectSell" });
    },
    afterFetchData(r) {
      if (this.id) {
        this.getBalance();
        //   this.calcModel();
      }
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },

    async changeStatus(status) {
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите ${status.value !== 2 ? "распровести" : "провести"} заказ?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (status.value !== 2) {
        let resp = await this.$axios.post("accounting/records/report-sql", {
          q: `select id from accounting_doc_order_withdrawal where parent_id=${this.id} and parent_name="${this.accDocName}"`,
        });
        if (resp.data.status == "ok") {
          if (resp.data.data.length > 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Есть связанный документ оплаты",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки",
          });
          return;
        }
      }
      this.loading = true;
      let data = { id: this.id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
    onSelectOperation(e) {
      if (!e.value) return;
      this.showCashDialog(0, e.id);
    },

    async showCashDialog(id, type) {
      this.idEditCash = id;
      let getConfig = await this.onConfigDocIn(type);
      if (!getConfig) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Документ не настроен",
        });
        return;
      }
      this.showCashCreateDialog = true;
    },
    onClickCash(e) {
      this.showCashDialog(e.row.id, e.row.operation_type);
      console.log(e);
    },
  },
};
</script>
